<template>
  <v-app class="page-review">
    <v-toolbar dark flat app>
      <v-toolbar-title flatclass="headline text-uppercase">
        <span>Sign and Agree</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon href="https://tenantevaluation.com/" target="_blank">
        <img src="../assets/TE-Icons.svg" height="20">
      </v-btn>
    </v-toolbar>
    <v-content>
      <template v-if="!signCompleted&&!emailSent">
        <v-layout row wrap pt-4>
          <v-flex xs12 text-xs-center>
            <h2 class="property-title">{{ currentApp.propertyName }}</h2>
          </v-flex>
        </v-layout>
        <v-card-text class="pt-0">
          <v-form ref="form">
            <v-container>
              <h3>
              Property Information <span class="float-right">Property ID <span>{{ currentApp.propertyId }}</span></span>
              </h3>
              <v-layout row wrap mb-3 pa-2 white>
                <v-flex xs12 sm6><em>Community Name</em> {{ currentApp.propertyName }}
                </v-flex>
                <v-flex xs12 sm6><em>Entity Type</em> {{ currentApp.entityType }}
                </v-flex>
                <v-flex xs12 sm6><em>EIN Number</em> {{ currentApp.einNumber }}
                </v-flex>
                <v-flex xs12 sm6><em>Business Origin</em> {{ currentApp.businessOrigin }}
                </v-flex>
                <v-flex xs12 sm6><em>Street</em> {{ currentApp.street }}
                </v-flex>
                <v-flex xs12 sm6><em>House No</em> {{ currentApp.houseNo }}
                </v-flex>
                <v-flex xs12 sm6><em>City</em> {{ currentApp.city }} {{ currentApp.zipcode }}
                </v-flex>
                <v-flex xs12 sm6><em>State</em> {{ currentApp.state }}
                </v-flex>
                <v-flex xs12 sm6><em>Years in Business</em> {{ currentApp.businessYears }}
                </v-flex>
                <v-flex xs12 sm6><em>Number of Units</em> {{ currentApp.unitsNumber }}
                </v-flex>
                <v-flex xs12 sm6><em>Business Email</em> {{ currentApp.businessEmail }}
                </v-flex>
                <v-flex xs12 sm6><em>Business Website</em> {{ currentApp.businessWebsite }}
                </v-flex>
                <v-flex xs12 sm6><em>Property Phone</em> {{ currentApp.propertyPhone }}
                </v-flex>
                <v-flex xs12 sm6><em>Property Phone Ext</em> {{ currentApp.propertyPhoneExt }}
                </v-flex>
              </v-layout>
              <h3>
              Authorized Users
              </h3>
              <!-- -->
              <v-layout row wrap mb-3 pa-2 white v-if="!this.currentApp.disablePricingInformation">
                <v-flex xs12>
                  <strong class="small-blue">Accounts Payable</strong>
                </v-flex>
                <v-flex xs12 sm6><em>Name</em> {{ currentApp.accountsPayableName }}
                </v-flex>
                <v-flex xs12 sm6><em>Email</em> {{ currentApp.accountsPayableEmail }}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-3 pa-2 white v-for="(item, index) in currentApp.users" v-bind:key="index">
                <v-flex xs12>
                  <strong class="small-blue">{{ item.position }}</strong>
                </v-flex>
                <v-flex xs12 sm6><em>Name</em> {{ item.name }}
                </v-flex>
                <v-flex xs12 sm6><em>Email</em> {{ item.email || 'N/A' }}
                </v-flex>
                <v-flex xs12 sm6><em>Phone</em> {{ item.phone }}
                </v-flex>
              </v-layout>
              <h3>
              Compliance Information
              </h3>
              <!-- -->
              <v-layout row wrap mb-3 pa-2 white>
                <v-flex xs12>
                  <strong class="small-blue">Fair credit reporting act</strong>
                </v-flex>
                <v-flex xs12><em>I understand that the credit report is to be used for tenancy purposes only.</em> {{ currentApp.creditReportUse? 'Yes' : 'No' }}
                </v-flex>
                <v-flex xs12><em>Do you understand that your business, as the end user, cannot resell the information?</em> {{ currentApp.agreeNoResell? 'Yes' : 'No' }}
                </v-flex>
                <v-flex xs12><em>Do you want the applicant to get a copy of the decision letter?</em> {{ currentApp.getDecisionLetter? 'Yes' : 'No' }}
                </v-flex>
                <v-flex xs12>
                  <strong class="small-blue">California civil code section 1785.14</strong>
                </v-flex>
                <v-flex xs12><em>Do you, Qualified Subscriber issue credit to consumers who appear in person on the basis of an application for credit submitted in person?</em> {{ currentApp.caComplianceInfo.issuesCredit? 'Yes' : 'No' }}
                </v-flex>
                <v-flex xs12>
                  <strong class="small-blue">Vermont fair credit reporting contract certification</strong>
                </v-flex>
                <v-flex xs12><em>Do you agree and understand the Vermont Credit reporting statute?</em> {{ currentApp.vtComplianceInfo.understandVCRS ? 'Yes' : 'No' }}
                </v-flex>
              </v-layout>
              <!-- -->
              <h3>
              Lease information
              </h3>
              <!-- -->
              <v-layout row wrap mb-3 pa-2 white>
                <template>
                  <v-flex xs12 sm6><em>Allow Leases?</em> {{ currentApp.allowLeases? 'Yes' : 'No' }}
                  </v-flex>
                </template>
                <template v-if="currentApp.allowLeases">
                  <v-flex xs12 sm6><em>Minimum Lease Length</em> {{ currentApp.leaseMin }}
                  </v-flex>
                  <v-flex xs12 sm6><em>Are lessees/tenants permitted to have pets?</em> {{ currentApp.leaseAllowPets? 'Yes' : 'No' }}
                  </v-flex>
                  <template v-if="currentApp.leaseAllowPets">
                    <v-flex xs12 sm6><em>Maximum allowed pet weight</em> {{ currentApp.leaseMaxPetWeight }}
                    </v-flex>
                    <v-flex xs12 sm6><em>Maximum number of pets</em> {{ currentApp.leaseMaxPetLimit }}
                    </v-flex>
                  </template>
                  <v-flex xs12 sm6><em>Would you like to collect lessees/tenants Proof of Income?</em> {{ currentApp.proofIncomeLease? 'Yes' : 'No' }}
                  </v-flex>
                  <v-flex xs12 sm6><em>Extra Document Limit Tenant</em> {{ currentApp.extraDocumentLimitTenant }}
                  </v-flex>
                  <v-flex xs12 sm6 v-bind:key="item.description" v-for="item in currentApp.leaseDocs">
                    <em v-if="item.included">Included</em>
                    <em v-if="!item.included">Custom</em> {{ item.description }} ({{ item.designation }})
                  </v-flex>
                </template>
              </v-layout>
              <h3>
              Purchase information
              </h3>
              <v-layout row wrap mb-3 pa-2 white>
                <template>
                  <v-flex xs12 sm6><em>Allow Purchases?</em> {{ currentApp.allowPurchases? 'Yes' : 'No' }}
                  </v-flex>
                </template>
                <template v-if="currentApp.allowPurchases">
                  <v-flex xs12 sm6><em>Are purchasers/owners permitted to have pets?</em> {{ currentApp.purchaseAllowPets? 'Yes' : 'No' }}
                  </v-flex>
                  <template v-if="currentApp.purchaseAllowPets">
                    <v-flex xs12 sm6><em>Purchase Max Pet Weight</em> {{ currentApp.purchaseMaxPetWeight }}
                    </v-flex>
                    <v-flex xs12 sm6><em>Purchase Max number of pets</em> {{ currentApp.purchaseMaxPetLimit }}
                    </v-flex>
                  </template>
                  <v-flex xs12 sm6><em>Would you like to collect purchasers/owners Proof of Income?</em> {{ currentApp.proofIncomePurchase? 'Yes' : 'No' }}
                  </v-flex>
                  <v-flex xs12 sm6><em>Extra Document Limit Purchase</em> {{ currentApp.extraDocumentLimitPurchase }}
                  </v-flex>
                  <v-flex xs12 sm6 v-bind:key="item.description" v-for="item in currentApp.purchaseDocs">
                    <em v-if="item.included">Included</em>
                    <em v-if="!item.included">Custom</em> {{ item.description }} ({{ item.designation }})
                  </v-flex>
                </template>
              </v-layout>
              <!-- -->
              <h3>
              Guest information
              </h3>
              <v-layout row wrap mb-3 pa-2 white>
                <template>
                  <v-flex xs12 sm6><em>Allow Guests?</em> {{ currentApp.allowGuests? 'Yes' : 'No' }}
                  </v-flex>
                </template>
                <template v-if="currentApp.allowGuests">
                  <v-flex xs12 sm6><em>Are guests permitted to have pets?</em> {{ currentApp.guestAllowPets? 'Yes' : 'No' }}
                  </v-flex>
                  <template v-if="currentApp.guestAllowPets">
                    <v-flex xs12 sm6><em>Guest Max Pet Weight</em> {{ currentApp.guestMaxPetWeight }}
                    </v-flex>
                    <v-flex xs12 sm6><em>Guest Max number of pets</em> {{ currentApp.guestMaxPetLimit }}
                    </v-flex>
                  </template>
                  <v-flex xs12 sm6><em>Would you like to collect guests Proof of Income?</em> {{ currentApp.proofIncomeGuest? 'Yes' : 'No' }}
                  </v-flex>
                  <v-flex xs12 sm6><em>Extra Document Limit Guest</em> {{ currentApp.extraDocumentLimitGuest }}
                  </v-flex>
                  <v-flex xs12 sm6 v-bind:key="item.description" v-for="item in currentApp.guestDocs">
                    <em v-if="item.included">Included</em>
                    <em v-if="!item.included">Custom</em> {{ item.description }} ({{ item.designation }})
                  </v-flex>
                </template>
              </v-layout>
              <!-- -->
              <h3>
              Important Property Requirements
              </h3>
              <v-layout row wrap mb-3 pa-2 white>
                <v-flex xs12>
                  <strong class="small-blue">Please include any important property requirements you want to inform the applicants before they apply.</strong>
                </v-flex>
                <v-flex xs12>
                  {{ currentApp.propertyNotes || 'N/A' }}
                </v-flex>
              </v-layout>
              <!-- -->
              <v-flex v-if="!this.currentApp.disablePricingInformation">
                <h3>
                Community Funding
                </h3>
                <v-layout row wrap mb-3 pa-2 white>
                  <v-flex xs12 sm6><em>How do you want us to fund  your account?</em> {{ currentApp.fundAccount }}
                  </v-flex>
                  <template v-if="currentApp.fundAccount === 'Already on file'">
                    <v-flex xs12 v-if="currentApp.achComments"><em>Comments:</em> {{ currentApp.achComments }}</v-flex>
                  </template>
                  <template v-if="currentApp.fundAccount === 'ACH'">
                    <v-flex xs12 sm6><em>Account Type</em> {{ currentApp.achAccountType }}
                    </v-flex>
                    <v-flex xs12 sm6><em>Routing Number</em> {{ currentApp.achRoutingNumber }}
                    </v-flex>
                    <v-flex xs12 sm6><em>Account Number</em> {{ currentApp.achAccountNumber }}
                    </v-flex>
                    <v-flex xs12 sm6><em>Institution Name</em> {{ currentApp.achInstitutionName }}
                    </v-flex>
                    <v-flex xs12 sm6><em>City</em> {{ currentApp.achCity }}
                    </v-flex>
                    <v-flex xs12 sm6><em>State</em> {{ currentApp.achState }}
                    </v-flex>
                    <v-flex xs12 sm6><em>Zip</em> {{ currentApp.achZip }}
                    </v-flex>
                    <v-flex xs12 sm6><em>Do you agree ACH Tems?</em> {{ currentApp.agreeACHTerms ? 'Yes' : 'No' }}
                    </v-flex>
                  </template>
                </v-layout>
              </v-flex>
              <h3>
              Credit Score
              </h3>
              <v-layout row wrap mb-3 pa-2 white>
                <v-flex xs12><em>Does your property require a minimum credit score?</em> {{ currentApp.requireMinScore? 'Yes': 'No' }}
                </v-flex>

                <v-flex xs12 v-if="currentApp.requireMinScore"><em>What will be the minimum average credit score for an applicant to be approved in your property</em> {{ currentApp.creditScore }}
                </v-flex>
              </v-layout>
              <!-- -->
              <h3>
              Documents
              </h3>
              <v-layout row wrap mb-3 pa-2 white>
                <v-flex xs12 sm6 text-xs-center v-if="currentApp.proposalDocUrl">
                  <v-btn :disabled="isLoading||showDocument" :loading="showDocument" @click="openDocument(currentApp.proposalDocUrl)" class="secondary"><img src="../assets/icon-doc.svg" class="mr-2" height="24"> Proposal</v-btn>
                </v-flex>
                <v-flex xs12 sm6 text-xs-center>
                  <v-btn :disabled="isLoading||showDocument" :loading="isLoading||showDocument" @click="openPreview()" class="secondary"><img src="../assets/icon-doc.svg" class="mr-2" height="24"> Preview Agreement</v-btn>
                </v-flex>
                <v-flex text-xs-center v-bind:key="item.documentUrl"  v-for="item in currentApp.onboardingDocuments">
                  <v-btn :disabled="isLoading||showDocument" :loading="showDocument" @click="openDocument(item.documentUrl)" class="secondary" v-if="item.description!='voided_check'"><img src="../assets/icon-doc.svg" class="mr-2" height="24"> {{ item.description }}.pdf</v-btn>
                </v-flex>
              </v-layout>
              <!-- -->
              <br>
              <br>
              <v-layout row wrap>
                <v-flex xs12 sm12 mb-4>
                  <p>Before continuing please read carefully the following sections in order to process your new account application.</p>
                  <v-layout row wrap align-center justify-start class="row-terms" v-for="(item, index) in sections" v-bind:key="item[1]">
                    <CheckMark :size="26" :delay="index*300" class="mr-3" style="flex: unset!important;" />
                    <v-flex text-xs-left>{{item[0]}}</v-flex>
                    <v-flex text-xs-right>
                      <v-btn round outline small color="primary" @click="openDialog(item)">Read</v-btn>
                    </v-flex>
                  </v-layout>
                  <p class="mt-3">I acknowledge that I have read and understood the preceding sections. I agree to abide by the Membership Agreement terms and to adhere to all the security policies herein instituted for the safety of the personal sensitive information of our consumers. This Membership Agreement Acknowledgement Form serves as acknowledgment to the full Membership Agreement which includes the sections listed previously.</p>
                </v-flex>
                <!--v-flex xs12>
                  <span class="title">Are you authorized to approve and sign this new Property Membership Agreement?</span>
                  <v-radio-group v-model="form.isSigner">
                    <v-radio color="blue" label="Yes" :value="true"></v-radio>
                    <v-radio color="blue" label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex-->
                <template v-if="form.isSigner === false">
                  <v-flex xs12 mb-3>
                    Please provide the full name, email and title of the person authorized to sign and agree to the new Property Membership Agreement.
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field :autofocus="$isDesktop()" xs12 v-model="form.signerName" label="First and Last Name" :rules="rules.required" required box></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field v-model="form.signerTitle" label="Title" :rules="rules.required" required box></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field xs12 v-model="form.signerEmail" label="Signer Email" :rules="rules.required" required box></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    The authorized signer will get a new email to review, agree and sign on the new Property Membership Agreement.
                  </v-flex>
                </template>
                <template v-if="form.isSigner !== false">
                  <v-flex xs12>
                    <span class="title">Do you agree with all the information provided in this membership agreement?</span>
                    <v-radio-group v-model="form.signerDenied">
                      <v-radio color="blue" label="Yes" :value="false"></v-radio>
                      <v-radio color="blue" label="No" :value="true"></v-radio>
                    </v-radio-group>
                  </v-flex>

                  <template v-if="form.signerDenied === false">
                    <v-flex xs12 mb-3>
                      Please enter your information
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field v-model="form.signerName" label="First and Last Name" :rules="rules.required" required box></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field v-model="form.signerTitle" label="Title" :rules="rules.required" required box></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field v-model="form.signingDate" label="Date" append-icon="event" readonly box></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <p>I acknowledge and agree that this Agreement may be executed by electronic signature, which shall be considered as an original signature for all purposes and shall have the same force and effect as an original signature.</p>
                      <div class="white pa-3">
                        <TeSignaturePad ref="TeSignaturePad" @onSignature="onSignatureChange" :signatureText="form.signerName"></TeSignaturePad>
                        <div class="v-text-field__details" v-if="signError">
                          <div class="v-messages theme--light error--text">
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">Signature is required.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-flex>
                  </template>

                  <template v-if="form.signerDenied === true">
                    <v-flex xs12 mb-3>
                      Please enter the reason.
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea auto-grow v-model="form.signerComments" box label="Enter your message here" value="" mb-0></v-textarea>
                    </v-flex>
                  </template>

                </template>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-layout mb-5 row justify-center class="form-buttons">
          <v-btn large depressed color="primary" @click="submitForm" :loading="isLoading" class="btn-toolbar" v-bind:class="{'blue--text': isLoading, 'white--text': !isLoading}" :disabled="isLoading">
            <template v-if="form.signerDenied === true">
              Send Comments
            </template>
            <template v-else>
              Agree &amp; Submit
            </template>
          </v-btn>
        </v-layout>
      </template>

      <template v-if="signCompleted">
        <v-form>
          <v-container>
            <v-flex xs12 headline mt-4 mb-5 black--text>Thank you!
              <br>You have successfully sign your Membership Agreement</v-flex>
            <v-layout wrap row text-xs-center>
              <v-flex xs12>
                <a :href="agreementUrl" :download="agreementFilename" target="_blank">
                  <svg class="pulse-svg mb-5" width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <circle class="circle first-circle" fill="#DDF0FF" cx="25.109" cy="50" r="50"></circle>
                    <circle class="circle second-circle" fill="#DDF0FF" cx="25.109" cy="50" r="50"></circle>
                    <circle class="circle third-circle" fill="#DDF0FF" cx="25.109" cy="50" r="50"></circle>
                    <circle class="circle-button" fill="#DDF0FF" cx="25.109" cy="45.01" r="46" />
                    <g transform="translate(0)" class="circle-icon">
                      <g transform="translate(17.227 24.219)">
                        <path fill="#2196F3" d="M3.827,34.672c-0.459,0-0.832-0.373-0.832-0.832c0-0.002,0-0.002,0-0.002v-10
                    c-0.001-0.459,0.371-0.834,0.83-0.834c0,0,0.001,0,0.002,0h10c0.46,0.002,0.831,0.379,0.829,0.838
                    c-0.003,0.457-0.373,0.826-0.829,0.83H4.661v9.168c0,0.461-0.374,0.834-0.834,0.834V34.672z" />
                      </g>
                      <path fill="#2196F3" d="M21.054,58.891H3.834C3.374,58.891,3,58.518,3,58.057V23.832C3,23.371,3.373,23,3.832,23
                  c0,0,0.001,0,0.002,0h27.229c0.459-0.002,0.833,0.369,0.834,0.83c0,0,0,0,0,0.002v24.221c0,0.221-0.088,0.434-0.245,0.59l-10,10
                  c-0.156,0.156-0.369,0.244-0.591,0.244L21.054,58.891z M4.663,57.223h16.046l9.515-9.516V24.666H4.664L4.663,57.223L4.663,57.223z
                  " />
                      <g transform="translate(5.627 6.322)">
                        <path fill="#2196F3" d="M13.623,24.668H3.829c-0.46,0.004-0.836-0.367-0.839-0.828c-0.003-0.461,0.368-0.836,0.829-0.84
                    c0.003,0,0.007,0,0.01,0h9.794c0.46-0.002,0.836,0.369,0.839,0.828c0.003,0.461-0.368,0.838-0.829,0.84
                    C13.63,24.668,13.626,24.668,13.623,24.668z" />
                      </g>
                      <g transform="translate(5.627 10.353)">
                        <path fill="#2196F3" d="M20.084,24.667H3.829c-0.46,0-0.833-0.373-0.833-0.834s0.373-0.834,0.833-0.834h16.255
                    c0.46,0,0.833,0.373,0.833,0.834S20.544,24.667,20.084,24.667z" />
                      </g>
                      <g transform="translate(5.627 14.382)">
                        <path fill="#2196F3" d="M20.084,24.669H3.829c-0.46,0.002-0.836-0.369-0.839-0.83c-0.003-0.459,0.368-0.836,0.829-0.838
                    c0.003,0,0.007,0,0.01,0h16.255c0.461,0.002,0.832,0.379,0.828,0.838C20.911,24.296,20.54,24.665,20.084,24.669z" />
                      </g>
                      <g transform="translate(5.627 18.41)">
                        <path fill="#2196F3" d="M10.634,24.668h-6.8c-0.46-0.002-0.832-0.379-0.829-0.84C3.008,23.373,3.377,23.002,3.834,23h6.8
                    c0.46,0.004,0.832,0.379,0.829,0.84C11.46,24.295,11.091,24.666,10.634,24.668z" />
                      </g>
                      <g transform="translate(24.651 24.427)">
                        <g transform="translate(0 11.415)">
                          <path fill="#2196F3" d="M12.78,31.178c-0.176,0-0.347-0.057-0.489-0.158l-8.947-6.514c-0.373-0.27-0.455-0.791-0.185-1.162
                      c0.271-0.373,0.792-0.455,1.164-0.186l0,0l8.457,6.154l8.463-6.154c0.372-0.27,0.894-0.188,1.164,0.186
                      c0.271,0.371,0.188,0.893-0.185,1.162l-8.951,6.514C13.129,31.123,12.957,31.178,12.78,31.178L12.78,31.178z" />
                        </g>
                        <g transform="translate(8.949)">
                          <path fill="#2196F3" d="M3.827,42.593c-0.46,0-0.832-0.371-0.832-0.832c0,0,0,0,0-0.002V23.835c0-0.461,0.373-0.834,0.833-0.834
                      s0.833,0.373,0.833,0.834v17.924C4.661,42.22,4.287,42.593,3.827,42.593L3.827,42.593z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </v-flex>
              <v-flex xs12 blue--text mb-5>
                <a :href="agreementUrl" :download="agreementFilename" target="_blank">
                  <strong>Download a copy of your membership agreement</strong>
                </a>
              </v-flex>
            </v-layout>
            <v-flex xs12 mb-3>
              Soon you will get an email to set up your property documents. In the meantime, if you have any questions you can email us at compliance@tenantevaluation.com.
            </v-flex>
          </v-container>
        </v-form>
      </template>

      <template v-if="emailSent">
        <v-form>
          <v-container>
            <v-flex xs12 headline mt-4 mb-5 black--text>Thank you!
              <br>You we will send your denial reason to the editor</v-flex>
            <v-layout row wrap>
              <v-flex xs12 sm12 text-xs-center>
                <div class="email-sent-circle"></div>
                <h4 class="email-sent-label">Email Sent!</h4>
                <p class="text-muted mb-5">We will contact you if further information is required.</p>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>

      <v-dialog v-model="showDocument" :fullscreen="true">
        <v-card class="dialog-document">
          <v-toolbar fixed>
            <v-toolbar-title>Review Document</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="white" flat @click="showDocument = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <iframe :src="documentUrl" v-if="documentUrl"></iframe>
          <v-btn large depressed color="primary" class="btn-toolbar" @click="showDocument = false">Close</v-btn>
        </v-card>
      </v-dialog>

      <v-dialog scrollable v-model="showDialog" width="800">
        <v-card class="dialogTEV">
          <v-card-title class="headline lighten-2" primary-title>
            {{dialogTitle}}
            <v-spacer></v-spacer>
            <v-btn color="white" flat icon @click="showDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-xs-center pa-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate/></v-card-text>
          <v-card-text v-html="dialogBody" v-else/>
          <v-divider></v-divider>
          <v-card-actions v-if="!isLoading">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="showDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-content>
  </v-app>
</template>
<script>
import {
  mapGetters,
  mapActions
}
from 'vuex'
import {
  ACTION_TYPES
}
from '../libs/constants'
import CheckMark from '../components/CheckMark'
import Validator from '../libs/validator'
import TeSignaturePad from '../components/TeSignaturePad'
import axios from 'axios'

export default {
  data() {
      const validator = new Validator();
      return {
        hasSign: false,
        isLoading: false,
        showDialog: false,
        showDocument: false,
        documentUrl: null,
        agreementUrl: null,
        agreementFilename: 'Tenant_Evaluation_Agreement.pdf',
        signError: false,
        signCompleted: false,
        emailSent: false,
        dialogTitle: '',
        dialogBody: '',
        sections: [
          ['FCRA Acknowledgment', 'fcra_acknowledgment'],
          ['End User License Agreement', 'enduseragreement'],
          ['Access Security Requirements', 'access_security_requirements'],
          ['Equifax Data Security', 'equifax_data_security'],
          ['Equifax Qualified Subscriber Terms and Conditions', 'equifax_qualified_subscriber_terms'],
          ['Credit Scoring Services Agreement', 'credit_scoring_services_agreement'],
          ['Notice to Users of Consumer Reports', 'notice_users_consumer_reports'],
          ['End User Certification of Compliance', 'enuser_certf_compliance'],
          ['Vermont Fair Credit Reporting Contact Certification', 'vermont_fair_credit_reporting_contact_certific']
        ],
        form: {
          disablePricingInformation: false,
          isSigner: null,
          signerDenied: null,
          signerName: null,
          signerTitle: null,
          signingDate: null,
          signature: null,
          signerComments: null,
          isBlacklist: null,
          blacklistNotes: null
        },
        rules: {
          required: [
            validator.rules.required()
          ]
        },
        propertyId: null,
        code: null
      }
    },
    components: {
      CheckMark,
      TeSignaturePad
    },
    filters: {
      pretty(value) {
        return JSON.stringify(value, null, 2);
      }
    },
    computed: {
      ...mapGetters({
        currentApp: 'currentApp',
        currentUser: 'currentUser'
      })
    },
    methods: {
      ...mapActions([
        ACTION_TYPES.UPDATE_APP_PROPERTIES
      ]),
      alertError(message) {
        this.$events.$emit('showSnackbar', {
          message: message,
          color: 'red'
        });
      },
      onSignatureChange(sign) {
        if (sign.isEmty) {
          this.hasSign = false
          this.form.signature = null
        } else {
          this.hasSign = true
          this.form.signature = sign.data
        }
      },
      openDocument(url) {
        this.documentUrl = url
        this.showDocument = true
      },
      openPreview(){
        const _this = this
        this.isLoading = true
        this.$http.post('/manager/properties/sign-pending/preview-agreement', {
          propertyId: this.propertyId,
          code: this.code
        })
        .then(response => {
          _this.documentUrl = response.data.result
          _this.showDocument = true
          _this.isLoading = false
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e)
        })
        .finally(() => {
          _this.isLoading = false
        })
      },
      submitForm() {
        const _this = this

        if(this.form.signerDenied === null) {
          return this.alertError('Please confirm if you agree with the terms of this agreement.')
        }

        if(!this.form.signerDenied&&!this.$refs.form.validate()) {
          return this.alertError('Please complete the required fields.')
        }

        if(this.form.signerDenied&&!this.form.signerComments) {
          return this.alertError('Please enter the reason.')
        }

        this.isLoading = true
        this.$http.post('/manager/properties/sign-pending', {
            propertyId: this.propertyId,
            code: this.code,
            signature: this.form.signature,
            signerDenied: this.form.signerDenied,
            signerComments: this.form.signerComments
          })
          .then(response => {
            if(!_this.form.signerDenied) {
              _this.signCompleted = true
              _this.agreementUrl = response.data.result
            }
            else {
              _this.emailSent = true
            }
          })
          .catch(e => {
            // eslint-disable-next-line
            console.error(e)
          })
          .finally(() => {
            _this.isLoading = false
          })
      },
      openDialog(el) {
        const _this = this
        this.isLoading = true
        this.dialogTitle = el[0]
        this.dialogBody = ''
        this.showDialog = true
        axios.get('/terms/' + el[1] + '.html')
          .then(response => {
            _this.dialogBody = response.data
          })
          .catch(e => {
            // eslint-disable-next-line
            console.error(e)
          })
          .finally(() => (this.isLoading = false))
      },
      loadProperty() {
        const _this = this
        this.isLoading = true
        this.$http.get('/manager/properties/sign-pending', {
            params: {
              propertyId: this.propertyId,
              code: this.code
            }
          })
          .then(response => {
            _this[ACTION_TYPES.UPDATE_APP_PROPERTIES](response.data)
          })
          .catch(e => {
            // eslint-disable-next-line
            console.error(e)
            _this.$router.push('/login')
          })
          .finally(() => {
            _this.isLoading = false
          })
      }
    },
    mounted() {
      this.propertyId = this.$route.params.propertyId
      this.code = this.$route.params.code
      this.loadProperty()
      this.form.signingDate = new Date().toISOString().substr(0, 10)
      if(!this.currentApp.disablePricingInformation) {
        this.sections.push(['ACH service (If applicable)', 'ach_service']);
      }
    }
}
</script>
<style>
.float-right {
  float: right;
}

.page-review hr {
  margin: 10px 0;
  border-top: 1px solid #ccc;
  box-sizing: content-box;
  height: 0;
}

.page-review em {
  display: block;
  color: #999;
  font-style: normal;
  font-size: 12px;
}

.page-review .small-blue {
  font-weight: bolder;
  color: #2096f3;
  margin: 20px 0 4px;
  display: block;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  font-size: 12px;
}

.page-review h3 {
  margin: auto -8px;
  color: #363041;
  font-size: 20px;
  border-bottom: 3px solid #363041;
  margin-bottom: 14px;
  padding-bottom: 6px;
  font-weight: 400;
}

#app .dialog-document {
  padding: 56px 0!important;
  height: 100%;
  background-color: #F8F8F8;
}

.dialog-document .v-toolbar__content {
  height: 56px!important;
}

.dialog-document iframe {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0!important;
}

.dialog-document .btn-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 56px!important;
  width: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0;
}



</style>
