var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "page-review" },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", flat: "", app: "" } },
        [
          _c(
            "v-toolbar-title",
            { attrs: { flatclass: "headline text-uppercase" } },
            [_c("span", [_vm._v("Sign and Agree")])]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                href: "https://tenantevaluation.com/",
                target: "_blank",
              },
            },
            [
              _c("img", {
                attrs: { src: require("../assets/TE-Icons.svg"), height: "20" },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-content",
        [
          !_vm.signCompleted && !_vm.emailSent
            ? [
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "", "pt-4": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "text-xs-center": "" } },
                      [
                        _c("h2", { staticClass: "property-title" }, [
                          _vm._v(_vm._s(_vm.currentApp.propertyName)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  { staticClass: "pt-0" },
                  [
                    _c(
                      "v-form",
                      { ref: "form" },
                      [
                        _c(
                          "v-container",
                          [
                            _c("h3", [
                              _vm._v("\n            Property Information "),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v("Property ID "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.currentApp.propertyId)),
                                ]),
                              ]),
                            ]),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "mb-3": "",
                                  "pa-2": "",
                                  white: "",
                                },
                              },
                              [
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Community Name")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.propertyName) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Entity Type")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.entityType) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("EIN Number")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.einNumber) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Business Origin")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.businessOrigin) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Street")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.street) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("House No")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.houseNo) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("City")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.city) +
                                      " " +
                                      _vm._s(_vm.currentApp.zipcode) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("State")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.state) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Years in Business")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.businessYears) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Number of Units")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.unitsNumber) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Business Email")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.businessEmail) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Business Website")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.businessWebsite) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Property Phone")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.propertyPhone) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("em", [_vm._v("Property Phone Ext")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentApp.propertyPhoneExt) +
                                      "\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("h3", [
                              _vm._v(
                                "\n            Authorized Users\n            "
                              ),
                            ]),
                            !this.currentApp.disablePricingInformation
                              ? _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "mb-3": "",
                                      "pa-2": "",
                                      white: "",
                                    },
                                  },
                                  [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c(
                                        "strong",
                                        { staticClass: "small-blue" },
                                        [_vm._v("Accounts Payable")]
                                      ),
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "" } },
                                      [
                                        _c("em", [_vm._v("Name")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.currentApp.accountsPayableName
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "" } },
                                      [
                                        _c("em", [_vm._v("Email")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.currentApp
                                                .accountsPayableEmail
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._l(
                              _vm.currentApp.users,
                              function (item, index) {
                                return _c(
                                  "v-layout",
                                  {
                                    key: index,
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "mb-3": "",
                                      "pa-2": "",
                                      white: "",
                                    },
                                  },
                                  [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c(
                                        "strong",
                                        { staticClass: "small-blue" },
                                        [_vm._v(_vm._s(item.position))]
                                      ),
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "" } },
                                      [
                                        _c("em", [_vm._v("Name")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(item.name) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "" } },
                                      [
                                        _c("em", [_vm._v("Email")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(item.email || "N/A") +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "" } },
                                      [
                                        _c("em", [_vm._v("Phone")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(item.phone) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _c("h3", [
                              _vm._v(
                                "\n            Compliance Information\n            "
                              ),
                            ]),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "mb-3": "",
                                  "pa-2": "",
                                  white: "",
                                },
                              },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("strong", { staticClass: "small-blue" }, [
                                    _vm._v("Fair credit reporting act"),
                                  ]),
                                ]),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("em", [
                                    _vm._v(
                                      "I understand that the credit report is to be used for tenancy purposes only."
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentApp.creditReportUse
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("em", [
                                    _vm._v(
                                      "Do you understand that your business, as the end user, cannot resell the information?"
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentApp.agreeNoResell
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("em", [
                                    _vm._v(
                                      "Do you want the applicant to get a copy of the decision letter?"
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentApp.getDecisionLetter
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("strong", { staticClass: "small-blue" }, [
                                    _vm._v(
                                      "California civil code section 1785.14"
                                    ),
                                  ]),
                                ]),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("em", [
                                    _vm._v(
                                      "Do you, Qualified Subscriber issue credit to consumers who appear in person on the basis of an application for credit submitted in person?"
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentApp.caComplianceInfo
                                          .issuesCredit
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("strong", { staticClass: "small-blue" }, [
                                    _vm._v(
                                      "Vermont fair credit reporting contract certification"
                                    ),
                                  ]),
                                ]),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("em", [
                                    _vm._v(
                                      "Do you agree and understand the Vermont Credit reporting statute?"
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentApp.vtComplianceInfo
                                          .understandVCRS
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("h3", [
                              _vm._v(
                                "\n            Lease information\n            "
                              ),
                            ]),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "mb-3": "",
                                  "pa-2": "",
                                  white: "",
                                },
                              },
                              [
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("em", [_vm._v("Allow Leases?")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentApp.allowLeases
                                              ? "Yes"
                                              : "No"
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                _vm.currentApp.allowLeases
                                  ? [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v("Minimum Lease Length"),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.currentApp.leaseMin) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Are lessees/tenants permitted to have pets?"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp.leaseAllowPets
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm.currentApp.leaseAllowPets
                                        ? [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("em", [
                                                  _vm._v(
                                                    "Maximum allowed pet weight"
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentApp
                                                        .leaseMaxPetWeight
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("em", [
                                                  _vm._v(
                                                    "Maximum number of pets"
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentApp
                                                        .leaseMaxPetLimit
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Would you like to collect lessees/tenants Proof of Income?"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp.proofIncomeLease
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Extra Document Limit Tenant"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp
                                                  .extraDocumentLimitTenant
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.currentApp.leaseDocs,
                                        function (item) {
                                          return _c(
                                            "v-flex",
                                            {
                                              key: item.description,
                                              attrs: { xs12: "", sm6: "" },
                                            },
                                            [
                                              item.included
                                                ? _c("em", [_vm._v("Included")])
                                                : _vm._e(),
                                              !item.included
                                                ? _c("em", [_vm._v("Custom")])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.description) +
                                                  " (" +
                                                  _vm._s(item.designation) +
                                                  ")\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("h3", [
                              _vm._v(
                                "\n            Purchase information\n            "
                              ),
                            ]),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "mb-3": "",
                                  "pa-2": "",
                                  white: "",
                                },
                              },
                              [
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("em", [_vm._v("Allow Purchases?")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentApp.allowPurchases
                                              ? "Yes"
                                              : "No"
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                _vm.currentApp.allowPurchases
                                  ? [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Are purchasers/owners permitted to have pets?"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp.purchaseAllowPets
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm.currentApp.purchaseAllowPets
                                        ? [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("em", [
                                                  _vm._v(
                                                    "Purchase Max Pet Weight"
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentApp
                                                        .purchaseMaxPetWeight
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("em", [
                                                  _vm._v(
                                                    "Purchase Max number of pets"
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentApp
                                                        .purchaseMaxPetLimit
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Would you like to collect purchasers/owners Proof of Income?"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp
                                                  .proofIncomePurchase
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Extra Document Limit Purchase"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp
                                                  .extraDocumentLimitPurchase
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.currentApp.purchaseDocs,
                                        function (item) {
                                          return _c(
                                            "v-flex",
                                            {
                                              key: item.description,
                                              attrs: { xs12: "", sm6: "" },
                                            },
                                            [
                                              item.included
                                                ? _c("em", [_vm._v("Included")])
                                                : _vm._e(),
                                              !item.included
                                                ? _c("em", [_vm._v("Custom")])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.description) +
                                                  " (" +
                                                  _vm._s(item.designation) +
                                                  ")\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("h3", [
                              _vm._v(
                                "\n            Guest information\n            "
                              ),
                            ]),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "mb-3": "",
                                  "pa-2": "",
                                  white: "",
                                },
                              },
                              [
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("em", [_vm._v("Allow Guests?")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentApp.allowGuests
                                              ? "Yes"
                                              : "No"
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                _vm.currentApp.allowGuests
                                  ? [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Are guests permitted to have pets?"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp.guestAllowPets
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm.currentApp.guestAllowPets
                                        ? [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("em", [
                                                  _vm._v(
                                                    "Guest Max Pet Weight"
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentApp
                                                        .guestMaxPetWeight
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("em", [
                                                  _vm._v(
                                                    "Guest Max number of pets"
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentApp
                                                        .guestMaxPetLimit
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Would you like to collect guests Proof of Income?"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp.proofIncomeGuest
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("em", [
                                            _vm._v(
                                              "Extra Document Limit Guest"
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentApp
                                                  .extraDocumentLimitGuest
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.currentApp.guestDocs,
                                        function (item) {
                                          return _c(
                                            "v-flex",
                                            {
                                              key: item.description,
                                              attrs: { xs12: "", sm6: "" },
                                            },
                                            [
                                              item.included
                                                ? _c("em", [_vm._v("Included")])
                                                : _vm._e(),
                                              !item.included
                                                ? _c("em", [_vm._v("Custom")])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.description) +
                                                  " (" +
                                                  _vm._s(item.designation) +
                                                  ")\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("h3", [
                              _vm._v(
                                "\n            Important Property Requirements\n            "
                              ),
                            ]),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "mb-3": "",
                                  "pa-2": "",
                                  white: "",
                                },
                              },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("strong", { staticClass: "small-blue" }, [
                                    _vm._v(
                                      "Please include any important property requirements you want to inform the applicants before they apply."
                                    ),
                                  ]),
                                ]),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.currentApp.propertyNotes || "N/A"
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            !this.currentApp.disablePricingInformation
                              ? _c(
                                  "v-flex",
                                  [
                                    _c("h3", [
                                      _vm._v(
                                        "\n              Community Funding\n              "
                                      ),
                                    ]),
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          row: "",
                                          wrap: "",
                                          "mb-3": "",
                                          "pa-2": "",
                                          white: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "", sm6: "" } },
                                          [
                                            _c("em", [
                                              _vm._v(
                                                "How do you want us to fund  your account?"
                                              ),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.currentApp.fundAccount
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm.currentApp.fundAccount ===
                                        "Already on file"
                                          ? [
                                              _vm.currentApp.achComments
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("em", [
                                                        _vm._v("Comments:"),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.currentApp
                                                              .achComments
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                        _vm.currentApp.fundAccount === "ACH"
                                          ? [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm6: "" },
                                                },
                                                [
                                                  _c("em", [
                                                    _vm._v("Account Type"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentApp
                                                          .achAccountType
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm6: "" },
                                                },
                                                [
                                                  _c("em", [
                                                    _vm._v("Routing Number"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentApp
                                                          .achRoutingNumber
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm6: "" },
                                                },
                                                [
                                                  _c("em", [
                                                    _vm._v("Account Number"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentApp
                                                          .achAccountNumber
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm6: "" },
                                                },
                                                [
                                                  _c("em", [
                                                    _vm._v("Institution Name"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentApp
                                                          .achInstitutionName
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm6: "" },
                                                },
                                                [
                                                  _c("em", [_vm._v("City")]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentApp.achCity
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm6: "" },
                                                },
                                                [
                                                  _c("em", [_vm._v("State")]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentApp.achState
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm6: "" },
                                                },
                                                [
                                                  _c("em", [_vm._v("Zip")]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentApp.achZip
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm6: "" },
                                                },
                                                [
                                                  _c("em", [
                                                    _vm._v(
                                                      "Do you agree ACH Tems?"
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentApp
                                                          .agreeACHTerms
                                                          ? "Yes"
                                                          : "No"
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("h3", [
                              _vm._v(
                                "\n            Credit Score\n            "
                              ),
                            ]),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "mb-3": "",
                                  "pa-2": "",
                                  white: "",
                                },
                              },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("em", [
                                    _vm._v(
                                      "Does your property require a minimum credit score?"
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentApp.requireMinScore
                                          ? "Yes"
                                          : "No"
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                                _vm.currentApp.requireMinScore
                                  ? _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("em", [
                                        _vm._v(
                                          "What will be the minimum average credit score for an applicant to be approved in your property"
                                        ),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentApp.creditScore) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("h3", [
                              _vm._v("\n            Documents\n            "),
                            ]),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "mb-3": "",
                                  "pa-2": "",
                                  white: "",
                                },
                              },
                              [
                                _vm.currentApp.proposalDocUrl
                                  ? _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm6: "",
                                          "text-xs-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "secondary",
                                            attrs: {
                                              disabled:
                                                _vm.isLoading ||
                                                _vm.showDocument,
                                              loading: _vm.showDocument,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openDocument(
                                                  _vm.currentApp.proposalDocUrl
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                src: require("../assets/icon-doc.svg"),
                                                height: "24",
                                              },
                                            }),
                                            _vm._v(" Proposal"),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      xs12: "",
                                      sm6: "",
                                      "text-xs-center": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "secondary",
                                        attrs: {
                                          disabled:
                                            _vm.isLoading || _vm.showDocument,
                                          loading:
                                            _vm.isLoading || _vm.showDocument,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openPreview()
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            src: require("../assets/icon-doc.svg"),
                                            height: "24",
                                          },
                                        }),
                                        _vm._v(" Preview Agreement"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  _vm.currentApp.onboardingDocuments,
                                  function (item) {
                                    return _c(
                                      "v-flex",
                                      {
                                        key: item.documentUrl,
                                        attrs: { "text-xs-center": "" },
                                      },
                                      [
                                        item.description != "voided_check"
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "secondary",
                                                attrs: {
                                                  disabled:
                                                    _vm.isLoading ||
                                                    _vm.showDocument,
                                                  loading: _vm.showDocument,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openDocument(
                                                      item.documentUrl
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    src: require("../assets/icon-doc.svg"),
                                                    height: "24",
                                                  },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.description) +
                                                    ".pdf"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _c("br"),
                            _c("br"),
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "Before continuing please read carefully the following sections in order to process your new account application."
                                      ),
                                    ]),
                                    _vm._l(
                                      _vm.sections,
                                      function (item, index) {
                                        return _c(
                                          "v-layout",
                                          {
                                            key: item[1],
                                            staticClass: "row-terms",
                                            attrs: {
                                              row: "",
                                              wrap: "",
                                              "align-center": "",
                                              "justify-start": "",
                                            },
                                          },
                                          [
                                            _c("CheckMark", {
                                              staticClass: "mr-3",
                                              staticStyle: {
                                                flex: "unset!important",
                                              },
                                              attrs: {
                                                size: 26,
                                                delay: index * 300,
                                              },
                                            }),
                                            _c(
                                              "v-flex",
                                              { attrs: { "text-xs-left": "" } },
                                              [_vm._v(_vm._s(item[0]))]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: { "text-xs-right": "" },
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      round: "",
                                                      outline: "",
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openDialog(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Read")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c("p", { staticClass: "mt-3" }, [
                                      _vm._v(
                                        "I acknowledge that I have read and understood the preceding sections. I agree to abide by the Membership Agreement terms and to adhere to all the security policies herein instituted for the safety of the personal sensitive information of our consumers. This Membership Agreement Acknowledgement Form serves as acknowledgment to the full Membership Agreement which includes the sections listed previously."
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                                _vm.form.isSigner === false
                                  ? [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mb-3": "" } },
                                        [
                                          _vm._v(
                                            "\n                  Please provide the full name, email and title of the person authorized to sign and agree to the new Property Membership Agreement.\n                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              autofocus: _vm.$isDesktop(),
                                              xs12: "",
                                              label: "First and Last Name",
                                              rules: _vm.rules.required,
                                              required: "",
                                              box: "",
                                            },
                                            model: {
                                              value: _vm.form.signerName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "signerName",
                                                  $$v
                                                )
                                              },
                                              expression: "form.signerName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Title",
                                              rules: _vm.rules.required,
                                              required: "",
                                              box: "",
                                            },
                                            model: {
                                              value: _vm.form.signerTitle,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "signerTitle",
                                                  $$v
                                                )
                                              },
                                              expression: "form.signerTitle",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              xs12: "",
                                              label: "Signer Email",
                                              rules: _vm.rules.required,
                                              required: "",
                                              box: "",
                                            },
                                            model: {
                                              value: _vm.form.signerEmail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "signerEmail",
                                                  $$v
                                                )
                                              },
                                              expression: "form.signerEmail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _vm._v(
                                          "\n                  The authorized signer will get a new email to review, agree and sign on the new Property Membership Agreement.\n                "
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm.form.isSigner !== false
                                  ? [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v(
                                              "Do you agree with all the information provided in this membership agreement?"
                                            ),
                                          ]),
                                          _c(
                                            "v-radio-group",
                                            {
                                              model: {
                                                value: _vm.form.signerDenied,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "signerDenied",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.signerDenied",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  color: "blue",
                                                  label: "Yes",
                                                  value: false,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  color: "blue",
                                                  label: "No",
                                                  value: true,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.form.signerDenied === false
                                        ? [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: { xs12: "", "mb-3": "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Please enter your information\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label:
                                                      "First and Last Name",
                                                    rules: _vm.rules.required,
                                                    required: "",
                                                    box: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.signerName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "signerName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.signerName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Title",
                                                    rules: _vm.rules.required,
                                                    required: "",
                                                    box: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.signerTitle,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "signerTitle",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.signerTitle",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm6: "" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Date",
                                                    "append-icon": "event",
                                                    readonly: "",
                                                    box: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.signingDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "signingDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.signingDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "I acknowledge and agree that this Agreement may be executed by electronic signature, which shall be considered as an original signature for all purposes and shall have the same force and effect as an original signature."
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  { staticClass: "white pa-3" },
                                                  [
                                                    _c("TeSignaturePad", {
                                                      ref: "TeSignaturePad",
                                                      attrs: {
                                                        signatureText:
                                                          _vm.form.signerName,
                                                      },
                                                      on: {
                                                        onSignature:
                                                          _vm.onSignatureChange,
                                                      },
                                                    }),
                                                    _vm.signError
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "v-text-field__details",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "v-messages theme--light error--text",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "v-messages__wrapper",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "v-messages__message",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Signature is required."
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm.form.signerDenied === true
                                        ? [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: { xs12: "", "mb-3": "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Please enter the reason.\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("v-textarea", {
                                                  attrs: {
                                                    "auto-grow": "",
                                                    box: "",
                                                    label:
                                                      "Enter your message here",
                                                    value: "",
                                                    "mb-0": "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.signerComments,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "signerComments",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.signerComments",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  {
                    staticClass: "form-buttons",
                    attrs: { "mb-5": "", row: "", "justify-center": "" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-toolbar",
                        class: {
                          "blue--text": _vm.isLoading,
                          "white--text": !_vm.isLoading,
                        },
                        attrs: {
                          large: "",
                          depressed: "",
                          color: "primary",
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                        },
                        on: { click: _vm.submitForm },
                      },
                      [
                        _vm.form.signerDenied === true
                          ? [_vm._v("\n            Send Comments\n          ")]
                          : [
                              _vm._v(
                                "\n            Agree & Submit\n          "
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.signCompleted
            ? [
                _c(
                  "v-form",
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              headline: "",
                              "mt-4": "",
                              "mb-5": "",
                              "black--text": "",
                            },
                          },
                          [
                            _vm._v("Thank you!\n            "),
                            _c("br"),
                            _vm._v(
                              "You have successfully sign your Membership Agreement"
                            ),
                          ]
                        ),
                        _c(
                          "v-layout",
                          {
                            attrs: { wrap: "", row: "", "text-xs-center": "" },
                          },
                          [
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.agreementUrl,
                                    download: _vm.agreementFilename,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "pulse-svg mb-5",
                                      attrs: {
                                        width: "50px",
                                        height: "50px",
                                        viewBox: "0 0 50 50",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "xmlns:xlink":
                                          "http://www.w3.org/1999/xlink",
                                      },
                                    },
                                    [
                                      _c("circle", {
                                        staticClass: "circle first-circle",
                                        attrs: {
                                          fill: "#DDF0FF",
                                          cx: "25.109",
                                          cy: "50",
                                          r: "50",
                                        },
                                      }),
                                      _c("circle", {
                                        staticClass: "circle second-circle",
                                        attrs: {
                                          fill: "#DDF0FF",
                                          cx: "25.109",
                                          cy: "50",
                                          r: "50",
                                        },
                                      }),
                                      _c("circle", {
                                        staticClass: "circle third-circle",
                                        attrs: {
                                          fill: "#DDF0FF",
                                          cx: "25.109",
                                          cy: "50",
                                          r: "50",
                                        },
                                      }),
                                      _c("circle", {
                                        staticClass: "circle-button",
                                        attrs: {
                                          fill: "#DDF0FF",
                                          cx: "25.109",
                                          cy: "45.01",
                                          r: "46",
                                        },
                                      }),
                                      _c(
                                        "g",
                                        {
                                          staticClass: "circle-icon",
                                          attrs: { transform: "translate(0)" },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(17.227 24.219)",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  fill: "#2196F3",
                                                  d: "M3.827,34.672c-0.459,0-0.832-0.373-0.832-0.832c0-0.002,0-0.002,0-0.002v-10\n                  c-0.001-0.459,0.371-0.834,0.83-0.834c0,0,0.001,0,0.002,0h10c0.46,0.002,0.831,0.379,0.829,0.838\n                  c-0.003,0.457-0.373,0.826-0.829,0.83H4.661v9.168c0,0.461-0.374,0.834-0.834,0.834V34.672z",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("path", {
                                            attrs: {
                                              fill: "#2196F3",
                                              d: "M21.054,58.891H3.834C3.374,58.891,3,58.518,3,58.057V23.832C3,23.371,3.373,23,3.832,23\n                c0,0,0.001,0,0.002,0h27.229c0.459-0.002,0.833,0.369,0.834,0.83c0,0,0,0,0,0.002v24.221c0,0.221-0.088,0.434-0.245,0.59l-10,10\n                c-0.156,0.156-0.369,0.244-0.591,0.244L21.054,58.891z M4.663,57.223h16.046l9.515-9.516V24.666H4.664L4.663,57.223L4.663,57.223z\n                ",
                                            },
                                          }),
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(5.627 6.322)",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  fill: "#2196F3",
                                                  d: "M13.623,24.668H3.829c-0.46,0.004-0.836-0.367-0.839-0.828c-0.003-0.461,0.368-0.836,0.829-0.84\n                  c0.003,0,0.007,0,0.01,0h9.794c0.46-0.002,0.836,0.369,0.839,0.828c0.003,0.461-0.368,0.838-0.829,0.84\n                  C13.63,24.668,13.626,24.668,13.623,24.668z",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(5.627 10.353)",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  fill: "#2196F3",
                                                  d: "M20.084,24.667H3.829c-0.46,0-0.833-0.373-0.833-0.834s0.373-0.834,0.833-0.834h16.255\n                  c0.46,0,0.833,0.373,0.833,0.834S20.544,24.667,20.084,24.667z",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(5.627 14.382)",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  fill: "#2196F3",
                                                  d: "M20.084,24.669H3.829c-0.46,0.002-0.836-0.369-0.839-0.83c-0.003-0.459,0.368-0.836,0.829-0.838\n                  c0.003,0,0.007,0,0.01,0h16.255c0.461,0.002,0.832,0.379,0.828,0.838C20.911,24.296,20.54,24.665,20.084,24.669z",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(5.627 18.41)",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  fill: "#2196F3",
                                                  d: "M10.634,24.668h-6.8c-0.46-0.002-0.832-0.379-0.829-0.84C3.008,23.373,3.377,23.002,3.834,23h6.8\n                  c0.46,0.004,0.832,0.379,0.829,0.84C11.46,24.295,11.091,24.666,10.634,24.668z",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(24.651 24.427)",
                                              },
                                            },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    transform:
                                                      "translate(0 11.415)",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "#2196F3",
                                                      d: "M12.78,31.178c-0.176,0-0.347-0.057-0.489-0.158l-8.947-6.514c-0.373-0.27-0.455-0.791-0.185-1.162\n                    c0.271-0.373,0.792-0.455,1.164-0.186l0,0l8.457,6.154l8.463-6.154c0.372-0.27,0.894-0.188,1.164,0.186\n                    c0.271,0.371,0.188,0.893-0.185,1.162l-8.951,6.514C13.129,31.123,12.957,31.178,12.78,31.178L12.78,31.178z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    transform:
                                                      "translate(8.949)",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "#2196F3",
                                                      d: "M3.827,42.593c-0.46,0-0.832-0.371-0.832-0.832c0,0,0,0,0-0.002V23.835c0-0.461,0.373-0.834,0.833-0.834\n                    s0.833,0.373,0.833,0.834v17.924C4.661,42.22,4.287,42.593,3.827,42.593L3.827,42.593z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  "blue--text": "",
                                  "mb-5": "",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.agreementUrl,
                                      download: _vm.agreementFilename,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "Download a copy of your membership agreement"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                          _vm._v(
                            "\n            Soon you will get an email to set up your property documents. In the meantime, if you have any questions you can email us at compliance@tenantevaluation.com.\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.emailSent
            ? [
                _c(
                  "v-form",
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              headline: "",
                              "mt-4": "",
                              "mb-5": "",
                              "black--text": "",
                            },
                          },
                          [
                            _vm._v("Thank you!\n            "),
                            _c("br"),
                            _vm._v(
                              "You we will send your denial reason to the editor"
                            ),
                          ]
                        ),
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm12: "",
                                  "text-xs-center": "",
                                },
                              },
                              [
                                _c("div", { staticClass: "email-sent-circle" }),
                                _c("h4", { staticClass: "email-sent-label" }, [
                                  _vm._v("Email Sent!"),
                                ]),
                                _c("p", { staticClass: "text-muted mb-5" }, [
                                  _vm._v(
                                    "We will contact you if further information is required."
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.showDocument,
                callback: function ($$v) {
                  _vm.showDocument = $$v
                },
                expression: "showDocument",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "dialog-document" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { fixed: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Review Document")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "white", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.showDocument = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.documentUrl
                    ? _c("iframe", { attrs: { src: _vm.documentUrl } })
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-toolbar",
                      attrs: { large: "", depressed: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showDocument = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { scrollable: "", width: "800" },
              model: {
                value: _vm.showDialog,
                callback: function ($$v) {
                  _vm.showDialog = $$v
                },
                expression: "showDialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "dialogTEV" },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "headline lighten-2",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dialogTitle) +
                          "\n          "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "white", flat: "", icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.showDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isLoading
                    ? _c(
                        "v-card-text",
                        { staticClass: "text-xs-center pa-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("v-card-text", {
                        domProps: { innerHTML: _vm._s(_vm.dialogBody) },
                      }),
                  _c("v-divider"),
                  !_vm.isLoading
                    ? _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showDialog = false
                                },
                              },
                            },
                            [_vm._v("\n            Close\n          ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }